<template>
  <CRow>
    <CCol cols="12" lg="12" class="pb-0 pt-0">
      <b-table class="data_table"
              :data="employeesData"
              :striped="true"
              :hoverable="true"
              :mobile-cards="true"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"                 
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize">
        
        <template slot-scope="props">
          <b-table-column field="name" sortable :label="$t('common.Employee')" :searchable="searchEnabled" width="80%">
            <div class="d-flex align-items-center">
              <userProfilePopover ref="userProfilePopover"
                                  mode="user" 
                                  :userName="props.row.name"
                                  :userProfileImage="props.row.profile_image" 
                                  :userIdExternal="props.row.user_id_external"
                                  :senderIdExternal="null"
                                  popoverPlacement="right"
                                  class="mr-2">
              </userProfilePopover>
              <div>
                <span>{{props.row.name}}</span>
                <span class="d-block mt-1 meta">{{props.row.team_name}} - {{props.row.department_name}}</span>
              </div>                        
            </div>                          
          </b-table-column>
          <b-table-column field="total_points" sortable :label="$t('common.Points')" width="20%">
            <div class="d-flex align-items-center credits">
              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
              <span>{{props.row.total_points}} {{props.row.total_points !== 1 ? $t('common.points') : $t('common.point')}}</span>
            </div>
          </b-table-column>        
        </template>
        <template slot="empty">
          <div class="text-center">
            <span>{{ $t('insights.No_points_scored') }}</span>
          </div>                
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'EmployeesTable',
  props: ['employees', 'environmentTag'],
  components: {
    userProfilePopover
  },  
  watch: {
    $props: {
      handler() {
        if(this.employees) {
          this.employeesData = Object.values(this.employees);
          // Check if the pagination should be activated
          (this.employeesData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {   
      apiBaseUrl: null,
      clientToken: null,        
      employeesData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      searchEnabled: false,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    
  }
}
</script>
